<template>
    <div>
        <vue-number v-model="price" v-bind="number"></vue-number>
    </div>
</template>
  
<script>
    import { component as VueNumber } from '@coders-tm/vue-number-format'
  
    export default {
      components: {
        VueNumber,
    },
  
    data() {
        return {
          price: null,
          number: {
            prefix: 'Rp ',
            decimal: ',',
            separator: '.',
            precision: 0,
            masked: false,
            acceptNegative: false,
            isInteger: true,
          },
        }
      },
    }

    
</script>